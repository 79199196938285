<template>
  <div>
    <v-row dense class="ma-1">
      <v-col cols="11">
        <gli-text-field
          hide-details
          class="ma-0 pa-0"
          :label="$t('Name')"
          :value="editedZone.displayName"
          @change="setName"
        ></gli-text-field>
      </v-col>
      <v-col cols="1">
        <panel-button
          @click="openSetColorDialog(true)"
          :tooltip="$t('Set zone color')"
          icon="mdi-palette"
        >
        </panel-button>
      </v-col>
    </v-row>
    <v-row dense class="mx-1 my-3">
      <v-col cols="11">
        <gli-text-field
          class="ma-0 pa-0"
          hide-details
          :label="$t('Unique identifier')"
          :value="editedZone.persistentId"
          disabled
        >
        </gli-text-field>
      </v-col>
      <v-col cols="1">
        <panel-button @click="generateId" :tooltip="$t('Generate ID')" icon="mdi-refresh">
        </panel-button>
      </v-col>
    </v-row>
    <gli-dialog
      :title="$t('Set zone color')"
      :editedObject="colorData"
      :deleteDisabled="true"
      :width="350"
      @save="updateColorAndOpacity"
      @cancel="openSetColorDialog(false)"
    >
      <v-row>
        <v-col align="center">
          <v-color-picker
            mode="rgba"
            :value="colorData ? colorData.color + (colorData.opacity * 255).toString(16) : ''"
            @update:color="updateColorData"
          ></v-color-picker>
        </v-col>
      </v-row>
    </gli-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import GliTextField from './base/GliTextField.vue';
import { ZoneActions } from '../store/actions';
import { ZoneGetters } from '../store/getters';
import GliDialog from './base/GliDialog.vue';
import PanelButton from '../components/PanelButton';
import confirm from '@/helpers/confirm';
import i18n from '@/i18n';

export default {
  components: { GliTextField, GliDialog, PanelButton },
  name: 'ZonePanel',
  props: [],

  data() {
    return {
      colorData: undefined
    };
  },

  computed: {
    ...mapState('zone', ['editedZone']),
    ...mapGetters('zone', [ZoneGetters.GET_COLOR_DATA])
  },

  methods: {
    ...mapActions('zone', [ZoneActions.SET_PROPERTIES, ZoneActions.GENERATE_ID, ZoneActions.SAVE]),

    setName(name) {
      this[ZoneActions.SET_PROPERTIES]({
        displayName: name
      });
      this.saveChange({
        displayName: name,
        persistentId: this.editedZone.persistentId
      });
    },

    async generateId() {
      const oldId = this.editedZone.persistentId;
      let shouldGenerateId = true;
      if (this.editedZone.persistentId) {
        shouldGenerateId = await confirm(
          i18n.t('Are you sure you want to generate a new identifier?'),
          {
            title: i18n.t('Generate ID'),
            color: 'warning',
            buttonTrueText: i18n.t('Generate'),
            buttonFalseText: i18n.t('Cancel')
          }
        );
      }
      if (shouldGenerateId) {
        this[ZoneActions.GENERATE_ID]();
        if (oldId !== this.editedZone.persistentId) {
          this.saveChange({ persistentId: this.editedZone.persistentId });
        }
      }
    },

    updateColorData(colorData) {
      this.colorData.color = colorData.hex;
      this.colorData.opacity = colorData.alpha;
    },

    updateColorAndOpacity() {
      this[ZoneActions.SET_PROPERTIES]({
        color: this.colorData.color,
        opacity: this.colorData.opacity
      });
      this.saveChange({ color: this.colorData.color, opacity: this.colorData.opacity });
      this.colorData = undefined;
    },

    saveChange(changes) {
      this.$emit('change', { id: this.editedZone.id, changes });
      this[ZoneActions.SAVE]();
    },

    openSetColorDialog(open) {
      if (open) {
        this.colorData = this[ZoneGetters.GET_COLOR_DATA];
      } else {
        this.colorData = undefined;
      }
    }
  }
};
</script>
